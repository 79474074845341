<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        Use the periodic table to determine the element (enter atomic symbol) that corresponds to
        each of the descriptions given below:
      </p>

      <p class="mb-3">
        a) the <b>halogen</b> in the same period as the <b>alkali metal</b> with
        <stemble-latex :content="nProtonsA" />
        protons.
      </p>
      <chemical-symbol-input
        v-model="inputs.elementA"
        class="mb-7"
        prepend-text="$\text{Element}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) the <b>alkaline earth metal</b> in the same period with the neutral <b>noble gas</b> that
        has
        <stemble-latex :content="nElectronsB" />
        electrons.
      </p>
      <chemical-symbol-input
        v-model="inputs.elementB"
        class="mb-7"
        prepend-text="$\text{Element}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) the <b>noble gas</b> in the same row as an isotope with
        <stemble-latex :content="nNeutronsC" />
        neutrons and
        <stemble-latex :content="nProtonsC" />
        protons.
      </p>
      <chemical-symbol-input
        v-model="inputs.elementC"
        class="mb-7"
        prepend-text="$\text{Element}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        d) the <b>noble gas</b> in the same period as
        <stemble-latex :content="elementNameD" />
        .
      </p>
      <chemical-symbol-input
        v-model="inputs.elementD"
        class="mb-7"
        prepend-text="$\text{Element}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';

export default {
  name: 'Question212',
  components: {
    StembleLatex,
    ChemicalSymbolInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        elementA: null,
        elementB: null,
        elementC: null,
        elementD: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    nProtonsA() {
      if (this.versionVariableA.value === 1) {
        return '$3$'; // Description for Li, answer = F
      } else if (this.versionVariableA.value === 2) {
        return '$11$'; // Description for Na, answer = Cl
      } else if (this.versionVariableA.value === 3) {
        return '$19$'; // Description for K, answer = Br
      } else if (this.versionVariableA.value === 4) {
        return '$37$'; // Description for Rb, answer = I
      } else if (this.versionVariableA.value === 5) {
        return '$55$'; // Description for Cs, answer = At
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    nElectronsB() {
      if (this.versionVariableB.value === 1) {
        return '$10$'; // Description for Ne, answer = Be;
      } else if (this.versionVariableB.value === 2) {
        return '$18$'; // Description for Ar, answer = Mg;
      } else if (this.versionVariableB.value === 3) {
        return '$36$'; // Description for Kr, answer = Ca;
      } else if (this.versionVariableB.value === 4) {
        return '$54$'; // Description for Xe, answer = Sr;
      } else if (this.versionVariableB.value === 5) {
        return '$86$'; // Description for Rn, answer = Ba;
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    nNeutronsC() {
      if (this.versionVariableC.value === 1) {
        return '$7$';
      } else if (this.versionVariableC.value === 2) {
        return '$14$';
      } else if (this.versionVariableC.value === 3) {
        return '$30$';
      } else if (this.versionVariableC.value === 4) {
        return '$70$';
      } else if (this.versionVariableC.value === 5) {
        return '$110$';
      } else {
        return '';
      }
    },
    nProtonsC() {
      if (this.versionVariableC.value === 1) {
        return '$6$'; // Description for C, answer = Ne;
      } else if (this.versionVariableC.value === 2) {
        return '$13$'; // Description for Al, answer = Ar;
      } else if (this.versionVariableC.value === 3) {
        return '$25$'; // Description for Mn, answer = Kr;
      } else if (this.versionVariableC.value === 4) {
        return '$50$'; // Description for Sn, answer = Xe;
      } else if (this.versionVariableC.value === 5) {
        return '$74$'; // Description for W, answer = Rn;
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    elementNameD() {
      if (this.versionVariableD.value === 1) {
        return '$\\text{gold}$'; // radon
      } else if (this.versionVariableD.value === 2) {
        return '$\\text{titanium}$'; // krypton
      } else if (this.versionVariableD.value === 3) {
        return '$\\text{niobium}$'; // xenon
      } else if (this.versionVariableD.value === 4) {
        return '$\\text{tungsten}$'; // radon
      } else if (this.versionVariableD.value === 5) {
        return '$\\text{antimony}$'; // xenon
      } else {
        return '';
      }
    },
  },
};
</script>
